export default {
  "row": "qPu",
  "item-bulk": "qlS",
  "active": "qlc",
  "disabled": "qlq",
  "cell": "qlZ body-2",
  "not-disabled": "qlR",
  "item-amount-disabled": "qlQ",
  "item-amount-credit": "qle",
  "no-padding": "qlB",
  "cell-content": "qlE",
  "centered-cell": "qld",
  "item-counterparty-icon": "qlI mr-16",
  "avatar": "qlt",
  "item-counterparty-name": "qlM",
  "item-counterparty-name-status": "qlP",
  "disputing-chargeback": "qll",
  "item-counterparty-name-text": "qlX",
  "item-amount": "qlC body-1",
  "item-amount-foreign": "qlk",
  "attachment-icon": "qlJ",
  "item-method": "qls",
  "bank-account-avatar": "qlF",
  "attachment-status-dot": "qlg",
  "attached": "qlD",
  "missing": "qlV",
  "item-attachment": "qlL",
  "compact": "qlT",
  "upsell-badge": "qlz",
  "fadein-item": "qla"
};
