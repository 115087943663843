export default {
  "team-card": "qEX",
  "card-link": "qEC",
  "shadow": "qEk",
  "history-button": "qEJ",
  "cards-button": "qEs",
  "menu-item": "qEF",
  "subtitle": "qEg",
  "delete-team": "qED"
};
