export default {
  "header-cell": "qPf",
  "header-content": "qPK caption-bold",
  "active": "qPG",
  "align-right": "qPr",
  "empty": "qPb",
  "align-checkbox": "qPy",
  "col-5": "qPU",
  "col-9": "qPj",
  "emitted-at-header-tag": "qPW"
};
