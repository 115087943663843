export default {
  "row": "qkz body-1",
  "active": "qka",
  "cell": "qkH",
  "empty": "qkO",
  "ellipsis": "qkm",
  "text-secondary": "qkp",
  "transfer-type": "qkx",
  "transfer-type__icon": "qkw",
  "quick-actions": "qkN",
  "amount": "qkv",
  "cell-approve": "qko",
  "cell-type": "qkn",
  "cell-requester": "qki",
  "account-select": "qkA",
  "row-compact": "qkY",
  "row-sidebar": "qkh",
  "cell-amount": "qkf",
  "hidden": "qkK",
  "animated": "qkG",
  "fadein-item": "qkr",
  "animated-cell": "qkb"
};
