export default {
  "cell": "qRn",
  "supplier-container": "qRi",
  "cell-label-container": "qRA",
  "cell-label": "qRY",
  "overflow": "qRh",
  "sub-label": "qRf body-2",
  "overdue": "qRK",
  "file-name-warning": "qRG",
  "missing": "qRr",
  "pending-icon": "qRb",
  "icon": "qRy",
  "flex-label": "qRU",
  "with-separator": "qRj",
  "self-invoice-icon": "qRW",
  "tooltip-wrapper": "qRu"
};
