export default {
  "container": "qFc",
  "container-column": "qFq",
  "content-wrapper": "qFZ",
  "media-wrapper": "qFR",
  "content": "qFQ",
  "title": "qFe",
  "subtitle": "qFB",
  "content-slot": "qFE",
  "sticky-panel": "qFd",
  "sticky-actions": "qFI"
};
