export default {
  "label-select": "cue",
  "puce": "cuB",
  "puce-wrapper": "cuE",
  "puce-container": "cud",
  "readonly": "cuI",
  "label-display": "cut",
  "label-list-name": "cuM body-1",
  "label-list-value": "cuP body-2"
};
