/* import __COLOCATED_TEMPLATE__ from './choose-transfer-type.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, BadgeHighlight } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { PAY_LATER_ELIGIBILITY_STATUSES } from 'qonto/constants/financing';
import {
  ELIGIBILITY_STATUS,
  INELIGIBILITY_REASON,
} from 'qonto/constants/international-out/eligibility';
import { FX_DISCOUNT_FAQ_URL } from 'qonto/constants/international-out/fee-discount';
import { STORAGE_KEYS } from 'qonto/constants/international-out/storage';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { NonEligibleModal } from 'qonto/react/components/transfers/international-out/modals';
import { PromotionalBanner } from 'qonto/react/components/transfers/international-out/promotional-banner';
import { isFxDiscountEnabled } from 'qonto/utils/international-out/quote';

interface ChooseTransferTypeSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ChooseTransferType extends Component<ChooseTransferTypeSignature> {
  badge = Badge;
  badgeHighlight = BadgeHighlight;
  instructionalTooltip = InstructionalTooltip;
  promotionalBanner = PromotionalBanner;

  @service declare intl: Services['intl'];
  @service declare reactPopup: Services['reactPopup'];
  @service declare router: Services['router'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare segment: Services['segment'];
  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  @tracked isInternationalOutTooltipDismissed =
    !variation('feature--boolean-international-out-new-currencies-2') ||
    Boolean(safeLocalStorage.getItem(STORAGE_KEYS.INSTRUCTIONAL_TOOLTIP_NEW_CURRENCIES));

  @tracked isFxPromotionalBannerDismissed = Boolean(
    safeLocalStorage.getItem(STORAGE_KEYS.FX_LANDING_PROMOTIONAL_BANNER_DISMISSED)
  );

  get isInternalTransfersEnabled() {
    let canCreateInternalTransfer = this.abilities.can('create internal transfer');
    let { hasMultipleActiveCurrentRemuneratedAccounts } = this.organizationManager.organization;

    return canCreateInternalTransfer && hasMultipleActiveCurrentRemuneratedAccounts;
  }

  get isEligibleToInternationalOutTransfer() {
    // @ts-expect-error
    return this.args.eligibilities?.internationalOut?.status === ELIGIBILITY_STATUS.ELIGIBLE;
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription?.findTrial('multi_transfers');
  }

  get isPayLaterDisclaimerVisible() {
    return (
      this.abilities.can('create pay later transfer in financing') &&
      // @ts-expect-error
      this.args.eligibilities?.payLater?.eligibility === PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE
    );
  }

  get isFxPromotionalBannerVisible() {
    let isEnabled = isFxDiscountEnabled(this.organizationManager.organization, 'full');
    return isEnabled && !this.isFxPromotionalBannerDismissed;
  }

  get isFxDiscountBadgeVisible() {
    let isEnabled = isFxDiscountEnabled(this.organizationManager.organization);
    return isEnabled && this.isEligibleToInternationalOutTransfer;
  }

  @action onTrialClick() {
    this.segment.track('upsell_bulk-transfer_clicked');
    if (this.trialInfo) {
      this.upsellManager.transitionToTrialLineup(this.trialInfo);
    } else {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
      });
    }
  }

  @action
  handleClose() {
    this.router.transitionTo('transfers.index');
  }

  @action
  handleGoExternal(withInvoice = false) {
    if (withInvoice) {
      this.segment.track('transfer-landing_page-billpay_clicked');
    } else {
      this.segment.track('transfer-landing_page_sepa_creation_clicked');
    }

    this.flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: withInvoice ? 'invoice-upload' : 'beneficiaries',
      queryParams: {
        ...(withInvoice &&
          variation('feature--boolean-improve-invoice-payment-flows') && {
            origin: TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE,
          }),
      },
    });
  }

  @action
  handleGoInternal() {
    this.router.transitionTo('transfers.internal.new');
  }

  @action
  handleGoBulk() {
    this.segment.track('transfer_bulk_create_page_loaded');

    this.router.transitionTo('transfers.multi.new', {
      queryParams: { currency: null },
    });
  }

  @action
  onCloseInternationalOutTooltip() {
    this.isInternationalOutTooltipDismissed = true;
    safeLocalStorage.setItem(STORAGE_KEYS.INSTRUCTIONAL_TOOLTIP_NEW_CURRENCIES, 'true');
  }

  @action
  onDismissFxPromotionalBanner() {
    this.isFxPromotionalBannerDismissed = true;
    safeLocalStorage.setItem(STORAGE_KEYS.FX_LANDING_PROMOTIONAL_BANNER_DISMISSED, 'true');
  }

  @action
  onClickFxPromotionalBannerLink() {
    // eslint-disable-next-line no-restricted-globals
    window.open(FX_DISCOUNT_FAQ_URL, '_blank');
  }

  handleGoInternationalOutTask = dropTask(async () => {
    if (this.abilities.can('create transfer') && this.isEligibleToInternationalOutTransfer) {
      this.onCloseInternationalOutTooltip();
      this.flowLinkManager.transitionTo({
        name: 'international-out',
        stepId: 'quote',
      });
    } else {
      await this.reactPopup.open(NonEligibleModal, {
        title: this.#getInternationalOutModalContent().title,
        subtitle: this.#getInternationalOutModalContent().subtitle,
        ctaText: this.intl.t('transfers.landing.international-modal.cta'),
      });
    }
  });

  #getInternationalOutModalContent() {
    if (
      // @ts-expect-error
      this.args.eligibilities?.internationalOut?.reason === INELIGIBILITY_REASON.KYB_NOT_APPROVED
    ) {
      return {
        title: this.intl.t('transfers.landing.international-modal.kyb-pending.title'),
        subtitle: this.intl.t('transfers.landing.international-modal.kyb-pending.subtitle.text', {
          // @ts-expect-error
          link: htmlSafe(
            `<a href=${this.zendeskLocalization.getLocalizedArticle(4359565)}
            )} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
              'transfers.landing.international-modal.kyb-pending.subtitle.link-text'
            )}</a>`
          ),
          htmlSafe: true,
        }),
      };
    }

    if (
      // @ts-expect-error
      this.args.eligibilities?.internationalOut?.reason ===
      INELIGIBILITY_REASON.FLEX_KYB_NOT_APPROVED
    ) {
      let organization = this.organizationManager.organization;

      if (organization.isDeFreelancer) {
        return {
          title: this.intl.t('transfers.landing.international-modal.flex-kyb.freelancers-DE.title'),
          subtitle: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.freelancers-DE.subtitle'
          ),
        };
      }

      if (organization.isDeCompanyCreation) {
        return {
          title: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-DE.title'
          ),
          subtitle: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-DE.subtitle.text',
            {
              // @ts-expect-error
              link: htmlSafe(
                `<a href=${this.intl.t(
                  'transfers.landing.international-modal.flex-kyb.unregistered-DE.subtitle.link'
                )} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
                  'transfers.landing.international-modal.flex-kyb.unregistered-DE.subtitle.link-text'
                )}</a>`
              ),
              htmlSafe: true,
            }
          ),
        };
      }

      if (organization.legalCountry === 'IT') {
        return {
          title: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-IT.title'
          ),
          subtitle: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-IT.subtitle.text',
            {
              // @ts-expect-error
              link: htmlSafe(
                `<a href=${this.intl.t(
                  'transfers.landing.international-modal.flex-kyb.unregistered-IT.subtitle.link'
                )} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
                  'transfers.landing.international-modal.flex-kyb.unregistered-IT.subtitle.link-text'
                )}</a>`
              ),
              htmlSafe: true,
            }
          ),
        };
      }

      if (organization.legalCountry === 'ES') {
        return {
          title: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-ES.title'
          ),
          subtitle: this.intl.t(
            'transfers.landing.international-modal.flex-kyb.unregistered-ES.subtitle.text',
            { htmlSafe: true }
          ),
        };
      }
    }

    return {
      title: this.intl.t('transfers.landing.international-modal.generic.title'),
      subtitle: this.intl.t('transfers.landing.international-modal.generic.subtitle.text', {
        // @ts-expect-error
        link: htmlSafe(
          `<a href=${this.zendeskLocalization.getLocalizedArticle(4359565)} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
            'transfers.landing.international-modal.generic.subtitle.FAQ-link.text'
          )}</a>`
        ),
        htmlSafe: true,
      }),
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::ChooseTransferType': typeof ChooseTransferType;
  }
}
