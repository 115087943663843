export default {
  "display-block": "qsV",
  "pay-later-tooltip": "qsL",
  "disabled": "qsT",
  "stepper": "qsz",
  "installments": "qsa",
  "flex-items": "qsH",
  "day-label": "qsO body-2",
  "installment-amount": "qsm body-1",
  "spacing": "qsp",
  "info-icon": "qsx",
  "detail": "qsw",
  "detail-value": "qsN",
  "extra": "qsv",
  "extra-value": "qso",
  "badge": "qsn",
  "title": "qsi",
  "link": "qsA"
};
