export default {
  "row": "cuT",
  "disabled": "cuz",
  "cell": "cua body-2",
  "cell-link": "cuH",
  "cell-content": "cuO",
  "item-truncate-text": "cum",
  "item-name-cell": "cup",
  "item-account-cell": "cux",
  "icon": "cuw",
  "icon-download": "cuN"
};
