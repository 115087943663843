export default {
  "insight-pill": "qZc",
  "interactive": "qZq",
  "focus": "qZZ",
  "subtitle": "qZR caption",
  "hidden": "qZQ",
  "missing-amounts-warning": "qZe",
  "totals": "qZB",
  "counter": "qZE title-3",
  "loading": "qZd",
  "error": "qZI",
  "title": "qZt title-4"
};
