export default {
  "wrapper": "qly",
  "title": "qlU",
  "activity-tag": "qlj",
  "select": "qlW",
  "tooltip": "qlu",
  "date-picker": "qXS",
  "internal-notes": "qXc",
  "scheduled": "qXq",
  "payroll-selector-wrapper": "qXZ"
};
