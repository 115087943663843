export default {
  "transaction": "qIh",
  "transaction-details": "qIf",
  "transaction-details-amount": "qIK body-1",
  "credit": "qIG",
  "negative-amount": "qIr",
  "transaction-details-description": "qIb body-2",
  "transaction-remove-cta": "qIy",
  "avatar": "qIU mr-16",
  "spinner": "qIj"
};
