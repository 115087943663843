export default {
  "modal": "qdD",
  "section": "qdV",
  "header": "qdL",
  "title": "qdT",
  "icon": "qdz",
  "content": "qda",
  "export-template-wrapper": "qdH",
  "export-template-radio": "qdO",
  "export-template-content": "qdm",
  "export-template-label": "qdp",
  "export-template-button": "qdx",
  "export-template-list": "qdw",
  "inactive": "qdN",
  "export-template-title": "qdv",
  "export-template-description": "qdo",
  "export-template-item-content": "qdn",
  "permission-disclaimer-container": "qdi",
  "export-template-footer": "qdA",
  "export-template-cta-container": "qdY",
  "upsell": "qdh",
  "upsell-section": "qdf",
  "_content": "qdK",
  "_cta": "qdG",
  "upsell-title": "qdr",
  "upsell-description": "qdb"
};
