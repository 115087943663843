export default {
  "vat-lines": "qgI",
  "vat-line": "qgt",
  "vat-input": "qgM",
  "vat-rate": "qgP",
  "vat-option-button": "qgl",
  "disabled": "qgX",
  "vat-option-tooltip": "qgC",
  "remove-label": "qgk",
  "remove-button": "qgJ",
  "vat-total": "qgs",
  "vat-total-amount": "qgF",
  "add-vat-line": "qgg",
  "with-vat-lines": "qgD"
};
