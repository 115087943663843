export default {
  "fileupload": "qFC",
  "file": "qFk",
  "label": "qFJ",
  "zone": "qFs",
  "error": "qFF",
  "hide": "qFg",
  "dropzone": "qFD",
  "hidden": "qFV",
  "upload-icon": "qFL",
  "dropping": "qFT",
  "bounce2": "qFz",
  "error-message": "qFa"
};
