export default {
  "modal": "qZY",
  "action-container": "qZh",
  "similar-label": "qZf",
  "info-icon": "qZK",
  "container": "qZG",
  "subtitle": "qZr caption-bold",
  "error": "qZb",
  "item-container": "qZy"
};
