export default {
  "review": "cUs",
  "review-content": "cUF",
  "review-parameters": "cUg",
  "row": "cUD",
  "row__border": "cUV",
  "label": "cUL",
  "value": "cUT",
  "error": "cUz",
  "tooltip-icon": "cUa",
  "terms-link": "cUH"
};
