export default {
  "transfer-header": "qPT",
  "infobox": "qPz header-section",
  "proof-section": "qPa",
  "proof-link": "qPH",
  "download-icon": "qPO",
  "cancel-button": "qPm",
  "request-refund-button": "qPp",
  "refund-requested-indicator": "qPx",
  "recall-indicator": "qPw",
  "payment-purpose": "qPN",
  "payment-purpose-label": "qPv",
  "repeat-infobox": "qPo qPz header-section",
  "additional-infobox": "qPn qPz header-section",
  "repeat-transfer-action": "qPi",
  "financing-not-compliant-disclaimer": "qPA"
};
