export default {
  "transfer-form": "qCe",
  "transfer-form-title": "qCB",
  "transfer-form-subtitle": "qCE",
  "form": "qCd",
  "form-footer": "qCI",
  "footer-btn": "qCt",
  "header": "qCM",
  "main-settings": "qCP",
  "additional-settings": "qCl",
  "form-container": "qCX"
};
