export default {
  "card-header": "qML",
  "counterparty-name": "qMT",
  "header-section": "qMz",
  "pan-section": "qMa header-section body-2",
  "infobox": "qMH header-section",
  "see-card-button": "qMO",
  "card-identifier-section": "qMm",
  "card-icon": "qMp",
  "card-status": "qMx",
  "nickname": "qMw body-1",
  "pan": "qMN body-2"
};
