export default {
  "filters-checkbox": "qIT mb-8",
  "csv-options-button": "qIz",
  "active": "qIa",
  "options-format-settings-wrapper": "qIH",
  "checkbox": "qIO",
  "options-format-settings": "qIm",
  "hidden": "qIp",
  "visible": "qIx",
  "disabled": "qIw",
  "buttons": "qIN"
};
