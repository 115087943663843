export default {
  "insight-pill": "qZM",
  "insight-pill-figure": "qZP",
  "insight-pill-renderer": "qZl",
  "loading": "qZX",
  "counter": "qZC",
  "error": "qZk",
  "insight-pill-label": "qZJ",
  "insight-pill-subtitle": "qZs caption",
  "hidden": "qZF",
  "missing-amounts-warning": "qZg",
  "missing-amounts": "qZD"
};
