export default {
  "supplier-selector-wrapper": "qqz",
  "select-supplier": "qqa",
  "dropdown": "qqH",
  "iban": "qqO",
  "card": "qqm body-2",
  "card-content": "qqp",
  "add-btn": "qqx btn btn--square btn--icon-only",
  "archived-card": "qqw",
  "title": "qqN body-1",
  "archived-title": "qqv",
  "edit-supplier-tooltip": "qqo",
  "add-supplier": "qqn btn btn--tertiary",
  "error": "qqi caption",
  "form-title": "qqA title2",
  "dropdown-menu": "qqY",
  "supplier-invoice-detail-menu": "qqh",
  "item-divider": "qqf",
  "menu-item": "qqK body-2 dropdown-overlay",
  "disabled": "qqG",
  "iban-error": "qqr",
  "iban-disclaimer": "qqb"
};
