export default {
  "row": "qQj",
  "greyed-out": "qQW",
  "supplier-name": "qQu",
  "cell": "qeS",
  "supplier": "qec",
  "email-address": "qeq",
  "outstanding-balance": "qeZ",
  "quick-actions": "qeR",
  "empty": "qeQ",
  "supplier-container": "qee",
  "grey-background": "qeB"
};
