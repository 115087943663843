export default {
  "cell": "qQS",
  "cell-label-container": "qQc",
  "cell-label": "qQq",
  "overflow": "qQZ",
  "missing": "qQR",
  "credit-note-amount": "qQQ",
  "payable-amount": "qQe",
  "amount": "qQB"
};
