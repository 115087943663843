export default {
  "attachment-sidebar": "qce",
  "sidebar-scroll": "qcB",
  "top-section": "qcE",
  "timeline-section": "qcd",
  "sidebar-header": "qcI",
  "matched-section": "qct",
  "collapsible-timeline": "qcM",
  "timeline-status-label": "qcP",
  "m-24": "qcl"
};
