export default {
  "wrapper": "qEK",
  "section-wrapper": "qEG",
  "header": "qEr",
  "title": "qEb body-1",
  "close-icon": "qEy",
  "section": "qEU",
  "section-title": "qEj caption-bold",
  "labels": "qEW",
  "labels-title": "qEu",
  "section-checkbox": "qdS",
  "grow": "qdc scroll",
  "label": "qdq",
  "buttons": "qdZ"
};
