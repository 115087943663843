export default {
  "card": "qFH",
  "card-primary": "qFO",
  "content": "qFm",
  "caption-text": "qFp",
  "content-primary": "qFx",
  "disabled": "qFw",
  "illustration": "qFN",
  "illustration-primary": "qFv",
  "illustration-disabled": "qFo",
  "cta": "qFn",
  "dismiss-button": "qFi",
  "buttons": "qFA",
  "required-badge": "qFY"
};
