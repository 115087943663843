export default {
  "header-cell": "qkM",
  "empty": "qkP",
  "header-type": "qkl",
  "header-requester": "qkX",
  "header-amount": "qkC",
  "header-approval": "qkk",
  "header-content": "qkJ caption-bold",
  "active": "qks",
  "row-compact": "qkF",
  "row-sidebar": "qkg",
  "hidden": "qkD",
  "animated": "qkV",
  "fadein-item": "qkL",
  "animated-cell": "qkT"
};
