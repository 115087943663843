export default {
  "item": "qZU",
  "checkbox": "qZj",
  "details": "qZW",
  "flex": "qZu",
  "header": "qRS qZu",
  "flex-no-gap": "qRc qZu",
  "file-name": "qRq",
  "label": "qRZ body-2",
  "self-icon": "qRR",
  "einvoice-icon": "qRQ",
  "type-value": "qRe",
  "with-separator": "qRB",
  "dimmed": "qRE"
};
