export default {
  "container": "qts",
  "item": "qtF caption-bold",
  "hover": "qtg",
  "active": "qtD",
  "name-wrapper": "qtV",
  "name": "qtL body-2",
  "count": "qtT",
  "actions-placeholder": "qtz",
  "actions": "qta",
  "show": "qtH",
  "action-button": "qtO"
};
