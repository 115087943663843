export default {
  "button-wrapper": "qIW",
  "btn-filter": "qIu",
  "name-wrapper": "qtS",
  "preset-name": "qtc",
  "cancel-placeholder": "qtq",
  "cancel-button": "qtZ",
  "dropdown-opened": "qtR",
  "dropdown-closed": "qtQ",
  "dropdown-menu": "qte",
  "loading-item": "qtB",
  "placeholder": "qtE",
  "label": "qtd",
  "list-body": "qtI",
  "list-item": "qtt",
  "error-state": "qtM body-2",
  "warning": "qtP",
  "warning-icon": "qtl",
  "footer": "qtX",
  "add-icon": "qtC",
  "empty-state": "qtk"
};
