export default {
  "savings-account": "cyo",
  "closed": "cyn",
  "badge": "cyi",
  "subtitle": "cyA",
  "amount": "cyY",
  "processing": "cyh",
  "gain": "cyf",
  "active": "cyK",
  "progress": "cyG",
  "progress-bar": "cyr",
  "progress-text": "cyb",
  "info": "cyy",
  "menu-item": "cyU body-2",
  "close-account": "cyj"
};
