export default {
  "wrapper": "cUU",
  "content": "cUj",
  "title": "cUW",
  "aside": "cUu",
  "description": "cjS",
  "faq-link": "cjc",
  "close-btn": "cjq",
  "container": "cjZ",
  "mandate-field-list": "cjR",
  "mandate-field-item": "cjQ",
  "mandate-field-item__dot": "cje",
  "mandate-field-item__text": "cjB"
};
