export default {
  "form": "qqQ",
  "form-title": "qqe title2",
  "row": "qqB",
  "col": "qqE",
  "actions": "qqd",
  "buttons": "qqI",
  "collapsible-timeline": "qqt",
  "link": "qqM",
  "timeline-status-label": "qqP",
  "section": "qql",
  "einvoicing-disclaimer": "qqX",
  "disclaimer-section": "qqC qql",
  "related-documents-card-margin-top": "qqk",
  "my-24": "qqJ"
};
