export default {
  "sidebar-content": "qXI",
  "section": "qXt",
  "header-wrapper": "qXM",
  "transfer-id": "qXP caption",
  "small-padding": "qXl",
  "title": "qXX",
  "subtitle": "qXC",
  "actions": "qXk"
};
