export default {
  "wrapper": "qXJ",
  "ember-power-select-dropdown": "qXs",
  "status": "qXF body-1",
  "error": "qXg",
  "success": "qXD",
  "type-container": "qXV",
  "type-close": "qXL",
  "date": "qXT",
  "close-icon": "qXz",
  "container": "qXa",
  "container-picto": "qXH",
  "container-picto-status": "qXO",
  "general": "qXm",
  "general-amount": "qXp",
  "unprocessed": "qXx",
  "fx-rate": "qXw",
  "general-counterparty": "qXN body-2",
  "infobox": "qXv",
  "account-infobox": "qXo qXv",
  "initiator-infobox": "qXn qXv",
  "transaction-infobox": "qXi qXv",
  "repeat-infobox": "qXA qXv",
  "item": "qXY body-2",
  "eye-icon": "qXh",
  "repeat-transfer-action": "qXf",
  "disclaimer": "qXK",
  "start-verification-link": "qXG",
  "financing-not-compliant-disclaimer": "qXr"
};
