export default {
  "row": "qlH",
  "col": "qlO",
  "header": "qlm",
  "block": "qlp",
  "col-5": "qlx",
  "col-6": "qlw",
  "col-9": "qlN",
  "col-12": "qlv",
  "hide": "qlo"
};
