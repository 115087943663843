export default {
  "sidepanel": "qQk",
  "close-icon": "qQJ",
  "close-button": "qQs",
  "supplier-card": "qQF",
  "supplier-card-details": "qQg",
  "supplier-name": "qQD title-3 mb-8",
  "supplier-card-placeholder": "qQV",
  "box": "qQL",
  "box-element": "qQT",
  "related-invoices-title": "qQz",
  "placeholder-container": "qQa",
  "related-invoices-wrapper": "qQH",
  "related-invoice-placeholder": "qQO",
  "related-invoice-placeholder-inner": "qQm",
  "actions": "qQp",
  "currency": "qQx"
};
