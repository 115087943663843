export default {
  "progress-container": "qEV",
  "line-container": "qEL",
  "dashed-line": "qET",
  "solid-line": "qEz",
  "circle-container": "qEa",
  "circle": "qEH",
  "current-step-dot": "qEO",
  "success-icon": "qEm",
  "rejected-icon": "qEp"
};
