export default {
  "details": "qcX",
  "details-header": "qcC",
  "details-header-amount": "qck",
  "details-content": "qcJ",
  "details-footer": "qcs",
  "invoice-status-container": "qcF body-2",
  "invoice-dates": "qcg",
  "overdue": "qcD",
  "details-list": "qcV",
  "details-list-item": "qcL",
  "details-list-item-label": "qcT body-2",
  "details-list-item-value": "qcz",
  "border-top": "qca",
  "description": "qcH",
  "description-value": "qcO",
  "edit-button": "qcm body-2",
  "edit-icon": "qcp",
  "badge-union": "qcx",
  "edit-description": "qcw",
  "amount-disclaimer": "qcN",
  "strike-amount": "qcv"
};
