export default {
  "row": "qJg body-1",
  "active": "qJD",
  "cell": "qJV",
  "empty": "qJL",
  "ellipsis": "qJT",
  "text-secondary": "qJz",
  "cell-type": "qJa",
  "cell-reason": "qJH",
  "cell-amount": "qJO",
  "row-sidebar": "qJm",
  "animated": "qJp",
  "fadein-item": "qJx",
  "animated-cell": "qJw"
};
