export default {
  "invoice-sidebar": "cjW",
  "body": "cju",
  "box": "cWS",
  "box-header": "cWc",
  "sidebar-box": "cWq",
  "border-top": "cWZ",
  "row": "cWR",
  "greyed-out": "cWQ",
  "strike-through": "cWe"
};
