export default {
  "sidebar-header": "qMv",
  "sidebar-header__top": "qMo",
  "sidebar-header__body": "qMn",
  "sidebar-header__content": "qMi",
  "sidebar-header__date": "qMA",
  "infobox": "qMY",
  "infobox--link": "qMh",
  "ghost": "qMf",
  "fail": "qMK",
  "r-transaction-disclaimer": "qMG",
  "hold-disclaimer": "qMr",
  "transaction-link": "qMb body-2",
  "icon-link": "qMy"
};
