export default {
  "modal": "qde",
  "section": "qdB",
  "header": "qdE",
  "title": "qdd",
  "separator-title": "qdI",
  "icon": "qdt",
  "formats": "qdM",
  "columns": "qdP",
  "column-list": "qdl",
  "draggable-column": "qdX",
  "column-field": "qdC",
  "column-selector": "qdk",
  "column-with-error": "qdJ",
  "handle": "qds",
  "label-color": "qdF",
  "disclaimer": "qdg"
};
