export default {
  "bank-account-option": "qBn",
  "row": "qBi",
  "active": "qBA",
  "animated": "qBY",
  "fadein-item": "qBh",
  "cell": "qBf body-2",
  "cell-content": "qBK",
  "subtitle": "qBG caption",
  "no-padding": "qBr",
  "empty": "qBb",
  "note": "qBy",
  "cell-content-amount": "qBU body-1",
  "cell-content-receipt": "qBj body-1",
  "cell-quick-actions": "qBW",
  "account-selector": "qBu",
  "cell-type": "qES"
};
