export default {
  "container": "qtr",
  "header": "qtb",
  "title": "qty caption-bold",
  "search-icon": "qtU",
  "text": "qtj body-2",
  "trigger": "qtW",
  "loading-state": "qtu",
  "dropzone-placeholder-wrapper": "qMS",
  "dropzone-placeholder": "qMc",
  "file-loading": "qMq",
  "search-qonto-cta": "qMZ",
  "search-qonto-cta-content": "qMR",
  "tooltip-wrapper": "qMQ",
  "invoices-tooltip": "qMe"
};
