export default {
  "row": "qBD",
  "active": "qBV",
  "dot": "qBL",
  "red": "qBT",
  "green": "qBz",
  "yellow": "qBa",
  "align-right": "qBH",
  "animated": "qBO",
  "fadein-item": "qBm",
  "cell": "qBp body-2",
  "cell-content": "qBx",
  "amount": "qBw body-1",
  "subtitle": "qBN caption",
  "status": "qBv",
  "cell-amount": "qBo"
};
