export default {
  "row": "qEc",
  "icon": "qEq",
  "cell": "qEZ",
  "cell-date": "qER",
  "cell-amount": "qEQ",
  "cell-status": "qEe",
  "cell-content": "qEB",
  "align-right": "qEE"
};
