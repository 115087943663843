export default {
  "beneficiary-details": "qJi",
  "beneficiary-account-types": "qJA",
  "divider": "qJY",
  "label": "qJh",
  "labels": "qJf",
  "error": "qJK",
  "link": "qJG caption",
  "sticky-panel": "qJr"
};
