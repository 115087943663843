export default {
  "header-cell": "qQA",
  "first-col": "qQY",
  "mid-col": "qQh",
  "outstanding-col": "qQf",
  "header-content": "qQK caption-bold",
  "active": "qQG",
  "quick-actions": "qQr",
  "empty": "qQb"
};
