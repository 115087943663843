export default {
  "modal": "cjt",
  "container": "cjM",
  "form-container": "cjP",
  "preview-container": "cjl",
  "preview-content": "cjX",
  "switch-container": "cjC",
  "supplier-invoice-preview": "cjk",
  "header": "cjJ",
  "footer": "cjs",
  "form-content": "cjF",
  "form-content-section": "cjg",
  "form-disclaimer": "cjD",
  "hidden": "cjV"
};
