import { template as template_880dfb32706a4cd391cb35089c5ceede } from "@ember/template-compiler";
import styles from './timeline.strict-module.css';
import Description from './timeline/content/description';
import Loading from './timeline/content/loading';
import StepState from './timeline/step-state';
let step = template_880dfb32706a4cd391cb35089c5ceede(`
  <li class={{styles.step-wrapper}} data-test-timeline-step ...attributes>
    <StepState
      @iconType={{@iconType}}
      @lineStyle={{@lineStyle}}
      @shouldHideProgressLine={{@shouldHideProgressLine}}
      @isLoading={{@isLoading}}
    />
    <div class={{styles.content-container}}>
      {{#if @isLoading}}
        <Loading data-test-timeline-loading-step />
      {{/if}}
      {{#unless @isLoading}}
        {{#if @description}}
          <Description
            @description={{@description}}
            @caption={{@caption}}
            @additionalInformation={{@additionalInformation}}
            @warningMessage={{@warningMessage}}
            @tooltipInfoMessage={{@tooltipInfoMessage}}
          />
        {{/if}}
        {{yield}}
      {{/unless}}
    </div>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Steps = template_880dfb32706a4cd391cb35089c5ceede(`
  <ol class={{styles.steps-container}} data-test-timeline ...attributes>
    {{#if (has-block 'steps')}}
      {{yield step to='steps'}}
    {{/if}}
  </ol>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Steps;
