export default {
  "custom-export-loading": "qIS",
  "title": "qIc",
  "description": "qIq",
  "section": "qIZ",
  "placeholder-avatar": "qIR",
  "_with-avatar": "qIQ",
  "_with-list": "qIe",
  "list-item": "qIB",
  "radio": "qIE",
  "header": "qId",
  "header-line": "qII"
};
